import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VuePageTransition from "vue-page-transition";
import VueTyperPlugin from "vue-typer";
import AOS from 'aos';
import 'aos/dist/aos.css';


Vue.use(VueTyperPlugin);
Vue.use(VuePageTransition);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
   created () {
    AOS.init()
  },
  render: function (h) { return h(App) }
}).$mount('#app')
