/* eslint-disable */ 
<template>
  <v-app>
    <!-- navigation drawer for mobile version -->
    <v-navigation-drawer v-model="sideNav" temporary src="@/assets/nav-img.jpg" app>
      <v-list nav rounded shaped>
        <v-list-item
          v-for="(menu, title) in menuItems"
          :key="title"
          router
          :to="menu.link"
          dense
          style="text-decoration: none; color: inherit;"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- navigation bar for desktop version -->
    <v-app-bar
      fixed
      color="#21212"
      dark
      shrink-on-scroll
      src="@/assets/DSC_9906.jpg"
      fade-img-on-scroll
      app
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)"
        ></v-img>
      </template>

      <!-- mobile nav icon -->
      <v-app-bar-nav-icon
        class="d-md-none"
        @click="sideNav = !sideNav"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Cirlex Systems</v-toolbar-title>

      <!-- menu items on the nav bar -->
      <template v-slot:extension>
        <v-tabs
          align-with-title
          class="d-none d-md-block amber darken-4"
          style="text-decoration:none;"
        >
          <v-tab  v-for="(menu, title) in menuItems"
          :key="title"
          router
          :to="menu.link">{{menu.title}}</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>

    <!-- footer -->
    <v-footer dark padless default app absolute>
      <v-card class="flex grey darken-4" flat tile>
        <!-- <v-card-text class="py-2 white--text text-center">
          <v-btn
            style="text-decoration: none; color: inherit;"
            v-for="icons in iconsList"
            :key="icons.icon"
            :href="icons.link"
            class="mx-4"
            dark
            icon
          >
            <v-icon size="24px">{{ icons.icon }}</v-icon>
          </v-btn>
        </v-card-text> -->
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong>Cirlex Systems</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    sideNav: false,

    // array for navigation
    menuItems: [
      {
        icon: "fas fa-home",
        title: "Home",
        link: "/"
      },
      {
        icon: "fas fa-info-circle",
        title: "About",
        link: "/about"
      },
      {
        icon: "fas fa-handshake",
        title: "Our services",
        link: "/services"
      },
      {
        icon: "fas fa-tasks",
        title: "Projects",
        link: "/projects"
      },
      {
        icon: "fas fa-comments",
        title: "Contact",
        link: "/contact"
      },
      // {
      //   icon: "fab fa-blogger",
      //   title: "Blog",
      //   link: "/blog"
      // },
      {
        icon: "fas fa-hand-holding-usd",
        title: "Donate",
        link: "/donate"
      }
    ],

    // array for footer icons
    iconsList: [
      {
        icon: "mdi-facebook",
        link: "#"
      },
      {
        icon: "mdi-twitter",
        link: "#"
      },
      {
        icon: "mdi-instagram",
        link: "#"
      }
    ]
  })
};
</script>
