import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"temporary":"","src":require("@/assets/nav-img.jpg"),"app":""},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c(VList,{attrs:{"nav":"","rounded":"","shaped":""}},_vm._l((_vm.menuItems),function(menu,title){return _c(VListItem,{key:title,staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"router":"","to":menu.link,"dense":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(menu.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(menu.title)}})],1)],1)}),1)],1),_c(VAppBar,{attrs:{"fixed":"","color":"#21212","dark":"","shrink-on-scroll":"","src":require("@/assets/DSC_9906.jpg"),"fade-img-on-scroll":"","app":""},scopedSlots:_vm._u([{key:"img",fn:function({ props }){return [_c(VImg,_vm._b({attrs:{"gradient":"to top right, rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)"}},'v-img',props,false))]}},{key:"extension",fn:function(){return [_c(VTabs,{staticClass:"d-none d-md-block amber darken-4",staticStyle:{"text-decoration":"none"},attrs:{"align-with-title":""}},_vm._l((_vm.menuItems),function(menu,title){return _c(VTab,{key:title,attrs:{"router":"","to":menu.link}},[_vm._v(_vm._s(menu.title))])}),1)]},proxy:true}])},[_c(VAppBarNavIcon,{staticClass:"d-md-none",on:{"click":function($event){_vm.sideNav = !_vm.sideNav}}}),_c(VToolbarTitle,[_vm._v("Cirlex Systems")])],1),_c(VMain,[_c(VSlideXTransition,{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c(VFooter,{attrs:{"dark":"","padless":"","default":"","app":"","absolute":""}},[_c(VCard,{staticClass:"flex grey darken-4",attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"py-2 white--text text-center"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Cirlex Systems")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }