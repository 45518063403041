import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
import about from "../views/about.vue";
import contact from "../views/contact.vue";
import projects from "../views/projects.vue";
import services from "../views/services.vue";
// import blog from "../views/blog.vue";
import donate from "../views/donate.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  },
  {
    path: "/about",
    name: "about",
    component: about
  },
  {
    path: "/contact",
    name: "contact",
    component: contact
  },
  // {
  //   path: "/blog",
  //   name: "blog",
  //   component: blog
  // },
  {
    path: "/projects",
    name: "projects",
    component: projects
  },
  {
    path: "/services",
    name: "services",
    component: services
  },
  {
    path: "/donate",
    name: "donate",
    component: donate
  }
];

const router = new VueRouter({
  routes
});

export default router;
