<template>
  <div>
    <section id="hero">

       <v-parallax
    dark
  
    src="@/assets/4.jpg"
  >
   <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
                    ]"
                    class="heading"
                  >
                    Welcome To Cirlex
                  </span>

                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
                    ]"
                    class="font-weight-black"
                  >
                    Systems
                  </span>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#inspiration')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
   </v-parallax>
    </section>

<!-- second section -->
    <section id="inspiration">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-1 font-weight-bold mb-3">Inspiration</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          We are a small group of people who aim at making your life easier and
          better. With this day and age there is alot to harvest from
          Technology, and with that said. why not provide services that can be
          used at anytime at your own comfort.
        </v-responsive>
<div data-aos="fade-right">
<v-avatar  class="elevation-12 mb-12" size="128">
          <v-img src="@/assets/logo.png"></v-img>
        </v-avatar>
</div>
        

        <div></div>
      </v-container>

      <div class="py-12"></div>
    </section>


<!-- third section -->
    <section id="features" class="grey lighten-2">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-1 font-weight-bold mb-3">Our Impact</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text }, i) in features"
            :key="i"
            cols="12"
            md="4"
          >
            <v-card class="py-12 px-4" color="grey lighten-3" flat>
              <v-theme-provider dark>
                <div data-aos="fade-left">
                  <v-avatar color="orange accent-3" size="88">
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                v-text="title"
              ></v-card-title>

              <v-card-text class="subtitle-1" v-text="text"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

<!-- fourth section stats -->
    <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="@/assets/DSC_9957.jpg"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <div
                data-aos="fade-right"
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                data-aos="fade-left"
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

<!-- last section -->
    <section id="purpose">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-1 font-weight-bold mb-3 text-uppercase text-center">
          Our Goals
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
          <div data-aos="fade-right">
 <v-img
              :src="require('../assets/' + src)"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>
          </div>
           
  <div data-aos="fade-right">
<h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>
  </div>
            
<div data-aos="fade-left">
<div  class="title font-weight-light mb-5" v-text="text"></div>
</div>
            
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    articles: [
      {
        src: "DSC_9840.jpg",
        title: "Water Treatment",
        text:
          "improving the quality of water to make it appropriate for a specific end-use"
      },
      {
        src: "DSC_0042.jpg",
        title: "Reduce cost",
        text:
          "Increase Health, economic savings. Costs related to diseases, such as health care, productivity losses, and premature mortality"
      },
      {
        src: "DSC_0001.jpg",
        title: "Water Supply",
        text:
          "reduction of water-borne diseases such as reduced incidence of diarrhoea, malaria or dengue fever. Almost ten per cent of the global burden of dis- ease could be prevented through water, sanitation and hygiene interven- tions."
      }
    ],
    features: [
      {
        icon: "mdi-account-group-outline",
        title: "Make People Happier",
        text:
          "Women and girls get their life back and start businesses improve their homes and take charge on their future."
      },
      {
        icon: "mdi-update",
        title: "Better life",
        text:
          "Reducing time and distance women and kids used to walk to obtain water"
      },
      {
        icon: "mdi-shield-outline",
        title: "helth Benefits",
        text:
          "Improves health Cost savings as people will spend less on public health and also,there shall be a reduction in the spread of waterborne diseases"
      }
    ],
    stats: [
      ["24/7", "Availability"],
      ["100%", "Support"],
      ["1m", "Location"],
      ["5m", "Clients"]
    ]
  }),
};
</script>
<style  scoped>
@import url(https://fonts.googleapis.com/css?family=Sofia);
.heading {
  font-family: "Sofia";
}
</style>