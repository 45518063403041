<template>
  <div class="bg">
    <!-- desktop view -->
    <div class="d-none d-md-block">
  <v-row>
   <v-col>
     <v-card width="500px">
      <v-card-title>You want to help!</v-card-title>
      <v-card-subtitle>We are happy to have you support our cause</v-card-subtitle>
      <v-card-text>
        <v-img src="@/assets/DSC_0043.jpg"></v-img>
      </v-card-text>
      <v-card-text>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse distinctio veritatis error animi. Consequuntur, mollitia, nesciunt corporis voluptate suscipit deleniti sint nulla itaque facere earum numquam. Vitae blanditiis quasi ad.
      </v-card-text>
    </v-card>
  </v-col>
  <v-col>
    <!-- donate form -->
    <v-card width="100%">
        <v-card-title>
          <span class="headline">Payment Form</span>
        </v-card-title>
        <v-form>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Card Number"
                  type="number"
                  v-model="cardNumber"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Account Holder"
                  v-model="accountHolder"
                  
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Expire Date"
                  v-model="expDate"
                  type="date"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CVV"
                  type="number"
                  v-model="cvv"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="email"
                  label="E-mail"
                  type="email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-1"
            text
            @click="clear()"
          >
            clear
          </v-btn>
          <v-btn
            color="orange darken-1"
            text
          >
            Donate
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
  </v-col>
 </v-row>
    </div>
  <!-- mobile view -->
    <div  class="d-md-none">
      <v-card
    class="mx-auto"
    max-width="344"
  >
    <v-img
      src="@/assets/DSC_9859.jpg"
      height="200px"
    ></v-img>

    <v-card-title>
      Willing to donate to our cause
    </v-card-title>

    <v-card-subtitle>
      Every penny counts to make a change.
    </v-card-subtitle>
         <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="orange lighten-2"
        text
        v-bind="attrs"
          v-on="on"
      >
        Donate
      </v-btn>
   </template>

      <v-card>
        <v-card-title>
          <span class="headline">Payment Form</span>
        </v-card-title>
        <v-form>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Card Number"
                  v-modal="cardNumber"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Account Holder"
                  v-modal="accountHolder"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                v-modal="expDate"
                  label="Expire Date"
                  type="date"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CVV"
                  v-modal="cvv"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="E-mail"
                  v-modal="email"
                  type="email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-1"
            text
            @click="cancel()"
          >
            Close
          </v-btn>
          <v-btn
            color="orange darken-1"
            text
            @click="dialog = false"
          >
            Donate
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
         Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, voluptatem, nostrum aut magnam omnis officia provident iste vitae libero voluptatum optio ad perferendis. Excepturi quo libero saepe, accusamus nam ex!
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      show: false,
     dialog: false,
     cardNumber:'',
     accountHolder:'',
     expDate:'',
     email:'',
     cvv:'',
    }),
    methods:{
      clear(){
        return this.cardNumber='',
        this.accountHolder='',
        this.expDate='',
        this.email='',
        this.cvv='';
      },
      cancel(){
        return this.dialog = false,
        this.cardNumber='',
        this.accountHolder='',
        this.expDate='',
        this.email='',
        this.cvv='';
      }
    }
  }
</script>
<style scoped>

</style>